import { convertCustomObjects } from "@/db";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import StoredUser from "@core-shared/interfaces/IStoredUser";

export default class Video {
	["id"]!: string;
	videoName: string = "";
	size: number = 0;
	gDriveFileId: string = "";
	uploadStatus: string = "";
	uploadTimestamp!: firebase.firestore.Timestamp;
	cloudStorageFileId: string = ""
	publicUrl: string = "";
	uploaderUser!: StoredUser;

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(video: Video): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return convertCustomObjects(video);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Video {
			const data = snapshot.data(options) ?? {};
			return Object.assign(new Video(), data);
		}
	}
}