import { convertCustomObjects } from '@/db';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Role {
  id: string = "";
  name: string = "";
  routes: Array<string> = [];

  constructor(partial?: Partial<Role>) {
    Object.assign(this, partial);
  }

  static FirestoreDataConverter = {
    toFirestore(user: Role): firebase.firestore.DocumentData {
      return convertCustomObjects(user);
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ): Role {
      const data = snapshot.data(options) ?? {};
      return Object.assign(new Role(), data);
    }
  }
}

export enum ROLE_IDS {
  default = "default",
  supervisor = "supervisor",
  pm = "pm",
  sitelead = "sitelead",
  estimator = "estimator",
  admin = "admin",
  clerk = "clerk",
  clerkManager = "clerkManager",
}



export const ROLES = [
  new Role({ id: ROLE_IDS.default, name: 'Default', routes: ['prices', 'businesses', 'customers'] }),
  new Role({ id: ROLE_IDS.supervisor, name: 'Supervisor', routes: ['all'], }),
  new Role({ id: ROLE_IDS.pm, name: 'Project Manager', routes: ['all'] }),
  new Role({ id: ROLE_IDS.sitelead, name: 'Site Lead', routes: ['all'] }),
  new Role({ id: ROLE_IDS.estimator, name: 'Estimator', routes: ['all'] }),
  new Role({ id: ROLE_IDS.admin, name: 'Administrator', routes: ['all'] }),
  new Role({ id: ROLE_IDS.clerk, name: 'Clerk', routes: ['invoices'] }),
  new Role({ id: ROLE_IDS.clerkManager, name: 'Clerk Manager', routes: ['all'] })
]