import { db } from '../../db';
import Video from '../../model/video';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.Video.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allvideos";

const state = {
	allvideos: new Array<Video>()
};

const getters = {
	videos(): Video[] {
		return [...state.allvideos]
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(Video.FirestoreDataConverter))
	}),
	addVideo(_: unused, video: Video): Promise<firebase.firestore.DocumentReference<Video>> {
		return db.collection(COLLECTION_NAME).withConverter(Video.FirestoreDataConverter).add(video);
	},
	editVideo(_: unused, video: Video): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(video.id).withConverter(Video.FirestoreDataConverter).set(video);
	},
	deleteVideo(_: unused, id: string): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}