import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import 'firebase/compat/functions';

let procoreUrl = 'https://us02.procore.com';
let supplierQuotesStorageBucket = '';
let surveyFilesStorageBucket = '';
let videosStorageBucket = '';
export const firebaseConfig = (() => {
	if (process.env.NODE_ENV === "development") {
		supplierQuotesStorageBucket = 'ccfnq-core-dev';
		surveyFilesStorageBucket = 'ccfnq-core-dev-survey';
		videosStorageBucket = 'ccfnq-core-dev-videos';
		procoreUrl = 'https://sandbox-monthly-us02.procore.com';
		return {
			apiKey: "AIzaSyCxuDIBPwYve_eXESf2qO1jjK-5BBsvme8",
			authDomain: "ccfnq-core-dev.firebaseapp.com",
			databaseURL: "https://ccfnq-core-dev.firebaseio.com",
			projectId: "ccfnq-core-dev",
			storageBucket: "ccfnq-core-dev.appspot.com",
			messagingSenderId: "713680162931",
			appId: "1:713680162931:web:18d7461b221b7f5ed11a06",
			measurementId: "G-D4DP8KRJPM"
		};
	} else {
		supplierQuotesStorageBucket = 'ccfnq-core-supplier-quotes';
		surveyFilesStorageBucket = 'ccfnq-core-survey';
		videosStorageBucket = 'ccfnq-core-videos';
		return {
			apiKey: "AIzaSyAXbVwpkJzIIVIDCvZuN09zOlDT2kzWCkk",
			authDomain: "ccfnq-core.firebaseapp.com",
			databaseURL: "https://ccfnq-core.firebaseio.com",
			projectId: "ccfnq-core",
			storageBucket: "ccfnq-core.appspot.com",
			messagingSenderId: "751432789734",
			appId: "1:751432789734:web:f1f51c8b397f5ec7051527",
			measurementId: "G-B3WSN9M591"
		};
	}
})();
// Get a Firestore instance
const configuredFirebase = firebase.initializeApp(firebaseConfig);
export const db = configuredFirebase.firestore();
db.settings({ ignoreUndefinedProperties: true, merge: true });
db.enablePersistence({ synchronizeTabs: true });
export default db;
export const fbIncrementOne = firebase.firestore.FieldValue.increment(1);
// const appCheck = firebase.appCheck();
// appCheck.activate('6Ld-NcIcAAAAAK7XVGosCDmZ8sm_GCdGqDeorL5d', true);

/*
	convertCustomObjects
	Unfortunately I was unable to get reflect-metadata to work, there must be a configuration issue that i am missing.
	I have built this function to check for properties in the object.
	The only case that we need to exclude currently is Firebase's Timestamp object.

	firebase needs plain javascript objects, it doesnt like typescript classes.
	this function is designed to recursively convert a custom object into a plain javascript object.
	the Firebase TimeStamp object needs to be sent to Firebase as the Timestamp object, cleaning it causes problems when recieving the data back.
	if the timestamp is cleaned, it will not be converted to a timestamp when recieving it back from the database or be stored as a timestamp in the database
*/
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const convertCustomObjects = (obj: any): any => {
	// const classesToExclude = [];
	// classesToExclude.push([Object.keys(new Timestamp(0, 0))]);
	if (typeof obj !== "object") {
		return obj
	}
	return Object.fromEntries(Object.entries(obj).map(
		([k, v]) => {
			if (Array.isArray(v)) {
				return [k, v.map(x => convertCustomObjects(x))]
			} else if (typeof v === "object" && (v !== null && ("nanoseconds" in v || "seconds" in v) === false /* todo: #403 logic here is faulty*/)) {
				return [k, convertCustomObjects({ ...v })]
			} else {
				return [k, v]
			}
		}
	))
}

// import fb from 'firebase';
// if (process.env.NODE_ENV === 'development') {
//   fb.app().functions('australia-southeast1').useFunctionsEmulator('http://localhost:5001');
// }
// configuredFirebase.analytics();
// configuredFirebase.performance();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const {
	Timestamp,
	GeoPoint
} = firebase.firestore;

export {
	Timestamp,
	GeoPoint,
	procoreUrl,
	supplierQuotesStorageBucket,
	surveyFilesStorageBucket,
	videosStorageBucket,
};