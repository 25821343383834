
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Appbar from "@/components/Appbar.vue";
import Login from "@/components/Login.vue";
import Navdrawer from "@/components/Navdrawer.vue";
import User from "@/model/user";
import Vue from "vue";
import { isEmpty } from "lodash";

Component.registerHooks(["beforeRouteEnter"]);

@Component({
	components: { Login, Appbar, Navdrawer },
})
export default class App extends Vue {
	/* eslint-disable @typescript-eslint/no-explicit-any */
	@Action("business/bindRef") bindBusinessesRef!: () => any;
	@Action("unit/bindRef") bindUnitsRef!: () => any;
	@Action("price/bindRef") bindPricesRef!: () => any;
	@Action("category/bindRef") bindCategorysRef!: () => any;
	@Action("customer/bindRef") bindCustomerRef!: () => any;
	@Action("job/bindRef") bindJobRef!: () => any;
	@Action("counter/bindJobCounterRef") bindJobCounterRef!: () => any;
	@Action("invoice/bindRef") bindInvoiceRef!: () => any;
	@Action("invoiceDeleted/bindRef") bindInvoiceDeletedRef!: () => any;
	@Action("vendor/bindRef") bindVendorRef!: () => any;
	@Action("costcode/bindRef") bindCostCodeRef!: () => any;
	@Action("user/bindRef") bindUserRef!: () => any;
	@Action("contact/bindRef") bindContactRef!: () => any;
	@Action("site/bindRef") bindSiteRef!: () => any;
	@Action("role/bindRef") bindRoleRef!: () => any;
	@Action("appSettings/bindRef") bindSettingsRef!: () => any;
	@Action("video/bindRef") bindVideoRef!: () => any;
	@Action("invoiceCount/bindRef") bindInvoiceCountRef!: () => any;

	/* eslint-enable @typescript-eslint/no-explicit-any */

	@Getter("user/getUserByIdWithoutStore") getUserByIdWithoutStore!: (userid: string) => Promise<User | undefined>;
	@Getter("getStoreUser") storeUser!: User;
	@Action("addStoreUser") addStoreUser!: (user: User) => any;
	@Action("removeStoreUser") removeStoreUser!: () => void;
	@Action("user/editUser") editUser!: (user: User) => any;
	@Action("role/initializeRoles") initializeRoles!: () => any;

	loading: boolean = true;

	async intializeBindingsAndRoles(): Promise<void> {
		const bindings = {
			businesses: this.bindBusinessesRef,
			units: this.bindUnitsRef,
			prices: this.bindPricesRef,
			category: this.bindCategorysRef,
			customer: this.bindCustomerRef,
			job: this.bindJobRef,
			jobCounter: this.bindJobCounterRef,
			invoice: this.bindInvoiceRef,
			invoiceDeleted: this.bindInvoiceDeletedRef,
			vendor: this.bindVendorRef,
			costCode: this.bindCostCodeRef,
			User: this.bindUserRef,
			contact: this.bindContactRef,
			site: this.bindSiteRef,
			role: this.bindRoleRef,
			settings: this.bindSettingsRef,
			video: this.bindVideoRef,
			invoiceCount: this.bindInvoiceCountRef
		};

		const bindPromises = Object.entries(bindings).map(([bindingName, bindingRef]) => {
			return bindingRef().catch((err: any) => console.log(`Firestore access not allowed for: ${bindingName}`));
		});

		await Promise.all(bindPromises);

		//bulk intitializers
		this.initializeRoles();
	}

	signOut(): void {
		firebase
			.auth()
			.signOut()
			.then(() => {
				this.removeStoreUser();
				//TODO: #318 unbind all references
			})
			.catch((error) => console.log(error));
	}

	beforeCreate(): void {
		firebase.auth().onAuthStateChanged(async (firebaseUser) => {
			if (firebaseUser) {
				let applicationUser = await this.getUserByIdWithoutStore(firebaseUser.uid);
				if (applicationUser?.id) {
					let changed: boolean = false;
					if (!isEmpty(firebaseUser.displayName) && firebaseUser.displayName != null && firebaseUser.displayName != applicationUser.displayName) {
						applicationUser.displayName = firebaseUser.displayName;
						changed = true;
					}
					if (!isEmpty(firebaseUser.photoURL) && firebaseUser.photoURL != null && firebaseUser.photoURL != applicationUser.photoURL) {
						applicationUser.photoURL = firebaseUser.photoURL;
						changed = true;
					}
					if (changed) await this.editUser(applicationUser);
				} else {
					applicationUser = new User(firebaseUser);
					await this.editUser(applicationUser);
				}
				await this.intializeBindingsAndRoles();
				this.addStoreUser(applicationUser);
			}
			this.loading = false;
		});
	}
}
