/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';

import business from './modules/business';
import category from './modules/category';
import customer from './modules/customer';
import job from './modules/job';
import price from './modules/price';
import unit from './modules/unit';
import counter from './modules/counter';
import invoice from './modules/invoice';
import invoiceDeleted from './modules/invoiceDeleted';
import vendor from './modules/vendor';
import costcode from './modules/costcode';
import user from './modules/user';
import role from './modules/role';
import User from '@/model/user';
import contact from './modules/contact';
import site from './modules/site';
import appSettings from './modules/appSettings'
import video from './modules/video'
import invoiceCount from './modules/invoiceCount'

Vue.use(Vuex)
export default new Vuex.Store({
	//strict: true, todo: enable this
	state: {
		packageVersion: 1,
		drawer: true,
		storeUser: undefined,
		menuItems: [
			{ icon: "mdi-home-city", text: "Home", to: { name: 'Home' } },
			{ icon: "mdi-domain", text: "Businesses", to: { name: 'Businesses' } },
			{ icon: "mdi-account-group-outline", text: "Customers", to: { name: 'Customers' } },
			{ subheader: true, header: "Supervisors" },
			{ divider: true },
			{ icon: "mdi-account-alert", text: "Labour Planner", to: { name: 'Labour' }, color: "purple" },
			{ icon: "mdi-account-hard-hat", text: "Jobs", to: { name: 'Jobs' }, color: "purple" },
			{ icon: "mdi-account-supervisor-circle", text: "Subcontractors", to: { name: 'Subcontractors' }, color: "purple" },
			{ icon: "mdi-database", text: "General Register", to: { name: 'GeneralRegister' }, color: "purple" },
			{ subheader: true, header: "Estimators" },
			{ divider: true },
			// { icon: "mdi-chart-gantt", text: "Gantt", to: { name: 'Gantt' } },
			{ icon: "mdi-package-variant-plus", text: "Supplier Quotes", to: { name: 'Supplier Quotes' } },
			{ divider: true },
			{ icon: "mdi-cash-register", text: "Central Register", to: { name: 'CentralRegister' } },
			{ icon: "mdi-receipt-text-check", text: "Invoices", to: { name: 'Invoices' }, color: "indigo" },
			{ divider: true },
			{ icon: "mdi-video", text: "360 Video Player", to: { name: 'Video Player' } },
			{ divider: true },
			{ icon: "mdi-database-import", text: "Import", to: { name: 'Import' } },
			{ icon: "mdi-cog", text: "Settings", to: { name: 'Settings' } },
		]
	},
	mutations: {
		...vuexfireMutations,
		drawer(state: any) {
			state.drawer = !state.drawer;
		}
	},
	actions: {
		addStoreUser({ state }, user: User) {
			state.storeUser = user;
		},
		removeStoreUser({ state }) {
			state.storeUser = undefined;
		}
	},
	getters: {
		menuItems(state: any) {
			return [...state.menuItems]
		},
		drawer(state: any) {
			return state.drawer;
		},
		getStoreUser: state => {
			return state.storeUser;
		},
	},
	modules: {
		business,
		price,
		unit,
		category,
		customer,
		job,
		counter,
		invoice,
		invoiceDeleted,
		vendor,
		costcode,
		user,
		contact,
		site,
		role,
		appSettings,
		video,
		invoiceCount
	}
});
